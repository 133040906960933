var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Repeating Forms" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "b-form",
            {
              ref: "form",
              staticClass: "repeater-form",
              style: { height: _vm.trHeight },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.repeateAgain($event)
                }
              }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-row",
                {
                  key: item.id,
                  ref: "row",
                  refInFor: true,
                  attrs: { id: item.id }
                },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Item Name",
                            "label-for": "item-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "item-name",
                              type: "text",
                              placeholder: "Vuexy Admin Template"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Cost", "label-for": "cost" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "cost",
                              type: "number",
                              placeholder: "32"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "Quantity", "label-for": "quantity" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "quantity",
                              type: "number",
                              placeholder: "1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { lg: "2", md: "1" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Price", "label-for": "price" } },
                        [
                          _c("b-form-input", {
                            attrs: { id: "pzrice", value: "32$", plaintext: "" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { lg: "2", md: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(234, 84, 85, 0.15)",
                              expression: "'rgba(234, 84, 85, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-danger" },
                          on: {
                            click: function($event) {
                              return _vm.removeItem(index)
                            }
                          }
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-25",
                            attrs: { icon: "XIcon" }
                          }),
                          _c("span", [_vm._v("Delete")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { cols: "12" } }, [_c("hr")])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(255, 255, 255, 0.15)",
              expression: "'rgba(255, 255, 255, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "primary" },
          on: { click: _vm.repeateAgain }
        },
        [
          _c("feather-icon", {
            staticClass: "mr-25",
            attrs: { icon: "PlusIcon" }
          }),
          _c("span", [_vm._v("Add New")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }